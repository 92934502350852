// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-admin-all-items-js": () => import("./../../../src/pages/admin/admin-all-items.js" /* webpackChunkName: "component---src-pages-admin-admin-all-items-js" */),
  "component---src-pages-icons-all-js": () => import("./../../../src/pages/icons/all.js" /* webpackChunkName: "component---src-pages-icons-all-js" */),
  "component---src-pages-icons-business-js": () => import("./../../../src/pages/icons/business.js" /* webpackChunkName: "component---src-pages-icons-business-js" */),
  "component---src-pages-icons-download-js": () => import("./../../../src/pages/icons/download.js" /* webpackChunkName: "component---src-pages-icons-download-js" */),
  "component---src-pages-icons-food-js": () => import("./../../../src/pages/icons/food.js" /* webpackChunkName: "component---src-pages-icons-food-js" */),
  "component---src-pages-icons-index-js": () => import("./../../../src/pages/icons/index.js" /* webpackChunkName: "component---src-pages-icons-index-js" */),
  "component---src-pages-icons-kids-js": () => import("./../../../src/pages/icons/kids.js" /* webpackChunkName: "component---src-pages-icons-kids-js" */),
  "component---src-pages-icons-lifestyle-js": () => import("./../../../src/pages/icons/lifestyle.js" /* webpackChunkName: "component---src-pages-icons-lifestyle-js" */),
  "component---src-pages-icons-symbol-js": () => import("./../../../src/pages/icons/symbol.js" /* webpackChunkName: "component---src-pages-icons-symbol-js" */),
  "component---src-pages-icons-transportation-js": () => import("./../../../src/pages/icons/transportation.js" /* webpackChunkName: "component---src-pages-icons-transportation-js" */),
  "component---src-pages-illustrations-download-js": () => import("./../../../src/pages/illustrations/download.js" /* webpackChunkName: "component---src-pages-illustrations-download-js" */),
  "component---src-pages-illustrations-index-js": () => import("./../../../src/pages/illustrations/index.js" /* webpackChunkName: "component---src-pages-illustrations-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-about-js": () => import("./../../../src/pages/info/about.js" /* webpackChunkName: "component---src-pages-info-about-js" */),
  "component---src-pages-info-contact-js": () => import("./../../../src/pages/info/contact.js" /* webpackChunkName: "component---src-pages-info-contact-js" */),
  "component---src-pages-info-terms-js": () => import("./../../../src/pages/info/terms.js" /* webpackChunkName: "component---src-pages-info-terms-js" */),
  "component---src-pages-info-thanks-js": () => import("./../../../src/pages/info/thanks.js" /* webpackChunkName: "component---src-pages-info-thanks-js" */),
  "component---src-pages-kung-fu-geek-download-js": () => import("./../../../src/pages/kung-fu-geek/download.js" /* webpackChunkName: "component---src-pages-kung-fu-geek-download-js" */),
  "component---src-pages-kung-fu-geek-general-js": () => import("./../../../src/pages/kung-fu-geek/general.js" /* webpackChunkName: "component---src-pages-kung-fu-geek-general-js" */),
  "component---src-pages-kung-fu-geek-index-js": () => import("./../../../src/pages/kung-fu-geek/index.js" /* webpackChunkName: "component---src-pages-kung-fu-geek-index-js" */),
  "component---src-pages-kung-fu-geek-job-title-js": () => import("./../../../src/pages/kung-fu-geek/job-title.js" /* webpackChunkName: "component---src-pages-kung-fu-geek-job-title-js" */),
  "component---src-pages-kung-fu-geek-not-advised-js": () => import("./../../../src/pages/kung-fu-geek/not-advised.js" /* webpackChunkName: "component---src-pages-kung-fu-geek-not-advised-js" */),
  "component---src-pages-kung-fu-geek-remote-work-js": () => import("./../../../src/pages/kung-fu-geek/remote-work.js" /* webpackChunkName: "component---src-pages-kung-fu-geek-remote-work-js" */),
  "component---src-pages-query-js": () => import("./../../../src/pages/query.js" /* webpackChunkName: "component---src-pages-query-js" */),
  "component---src-pages-tools-featured-image-generator-js": () => import("./../../../src/pages/tools/featured-image-generator.js" /* webpackChunkName: "component---src-pages-tools-featured-image-generator-js" */),
  "component---src-pages-tools-index-js": () => import("./../../../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */)
}

